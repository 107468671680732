.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 24px;
  line-height: 1.25;
  cursor: pointer;
  color: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  padding-left: 30px;
  padding-right: 10px;
  background-size: 18px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.2s;
}
.button:hover,
.button:focus {
  color: #d41b16;
  padding-left: 40px;
  padding-right: 0;
}
@media (max-width: 1439px) {
  .button {
    font-size: 18px;
    line-height: 1.33333333;
    background-position: 0 2px;
  }
}
.section--two .content {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.section--two .subcontent {
  width: calc(50% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
.section--two .subcontent.subcontent--1 {
  width: calc(100% - 30px);
}
/*# sourceMappingURL=./screen-medium.css.map */